<template>
  <div class="wl-home">
    <div class="w">
      <div class="search">
        <el-input
          placeholder="输入搜索视频标题"
          v-on:keyup.enter.native="getDate()"
          v-model="store.serchVal"
          class="input-with-select"
        >
          <!-- suffix-icon="el-icon-search" -->
          <el-button @click="getDate()" slot="append" icon=""> 搜索</el-button>
        </el-input>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="综合" name="first"> </el-tab-pane>
        <el-tab-pane label="视频" name="second"> </el-tab-pane>
        <el-tab-pane label="文档" name="third"> </el-tab-pane>
      </el-tabs>
      <div class="actList" v-if="act == 1">
        <a href="javascript:;">
          <div @click="actClick(1)" :class="{ act: act2 == 1 }" class="item">
            综合排序
          </div>
        </a>
        <a href="javascript:;">
          <div @click="actClick(2)" :class="{ act: act2 == 2 }" class="item">
            最新发布
          </div>
        </a>
        <a href="javascript:;">
          <div @click="actClick(3)" :class="{ act: act2 == 3 }" class="item">
            最多点赞
          </div>
        </a>
        <a href="javascript:;">
          <div @click="actClick(4)" :class="{ act: act2 == 4 }" class="item">
            最多收藏
          </div>
        </a>
      </div>
      <div class="actList" v-if="act == 2">
        <a href="javascript:;">
          <div @click="actClick(1)" :class="{ act: act2 == 1 }" class="item">
            综合排序
          </div>
        </a>
        <a href="javascript:;">
          <div @click="actClick(2)" :class="{ act: act2 == 2 }" class="item">
            最多阅读
          </div>
        </a>
        <a href="javascript:;">
          <div @click="actClick(3)" :class="{ act: act2 == 3 }" class="item">
            最新发布
          </div>
        </a>
      </div>

      <el-row :gutter="13">
        <el-col :span="6" v-for="(item, index) in list" :key="index">
          <box-2-item :it="item" v-if="item.type == 1"></box-2-item>
          <box-3-item v-if="item.type == 2" class="word"></box-3-item>
        </el-col>
      </el-row>
      <div class="no" v-if="list.length == 0">
        <img src="../../assets/images/listNull.png" alt="" />
      </div>

      <el-pagination
        background
        hide-on-single-page
        @current-change="handleCurrentChange"
        :current-page.sync="pageNum"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Store from "../../store";
import Box2Item from "../home/box2-item.vue";
import Box3Item from "../home/box3-item.vue";
export default {
  components: { Box2Item, Box3Item },
  mounted() {
    this.getDate();
  },

  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      activeName: "first",
      act: 0,
      act2: 0,
      list: [],
      total: 100,
      pageNum: 1,
      pageSize: 8,
    };
  },
  methods: {
    // 选择某一个选项 第一个选项
    handleClick(tab, event) {
      console.log(tab, event);
      this.act = tab.index;
      this.act2 = 1;
      this.pageNum = 1;
      this.list = [];
      // 如果是精品
      this.getDate();
    },
    // 选择某一个选项 第二个选项
    actClick(val) {
      this.act2 = val;
      this.getDate();
    },
    async getDate() {
      if (this.act == 0) {
        this.getZHDate();
      } else if (this.act == 1) {
        this.getVideoDate();
      } else if (this.act == 2) {
        this.getWordDate();
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getDate();
    },
    // 获取综合数据
    async getZHDate() {
      let { data: res } = await this.$http.post("/videoInfo/search", {
        content: this.store.serchVal,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.list = res.data.pageResult.records;
      console.log(this.list);
      this.total = Number(res.data.totalNum);
    },
    // 获取视频数据
    async getVideoDate() {
      let { data: res } = await this.$http.post("/videoInfo/searchVideo", {
        content: this.store.serchVal,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        //  1.综合排序 2.最新发布 3.最多点赞 4.最多收藏
        sort: this.act2,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.list = res.data.records;
      this.total = Number(res.data.total);
    },
    // 获取文档数据
    async getWordDate() {
      let { data: res } = await this.$http.post("/videoInfo/searchDoc", {
        content: this.store.serchVal,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        // 排序方式 1.综合排序 2.最新发布 3.最多阅读
        sort: this.act2,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.list = res.data.records;
      this.total = Number(res.data.total);
    },
  },
  beforeDestroy() {
    this.store.serchVal = "";
  },
};
</script>

<style lang="less" scoped>
.wl-home {
  padding-bottom: 50px;
  .search {
    text-align: center;
    /deep/ .el-input {
      margin: 0 auto;
      /* 矩形 3 */
      width: 560px;
      // height: 60px;
      // line-height: 60px;
      border-radius: 5px;
      box-sizing: border-box;
      border: 1px solid #999999;
      .el-input__inner {
        height: 60px;
        line-height: 60px;
      }
      .el-button {
        // margin-right: -21px;
        width: 93px;
        background-color: #ff7d41;
        color: #fff;
        height: 60px;
      }
    }
  }
  .actList {
    // display: flex;
    // justify-content: space-around;
    // margin-top: 21px;
    padding-bottom: 30px;
    white-space: nowrap;

    overflow: auto;

    .item {
      margin-right: 35px;
      padding: 5px 0;
      font-size: 14px;
      border-bottom: 1px solid #0000;
      display: inline-block;
      /* 全部 */
    }
    .act {
      border-bottom: 1px solid #ff7d41;
      color: #ff7d41;
      // border-image: -webkit-linear-gradient(#ff942c, #fd2894) 20 20;
    }
  }
  .list {
    height: 500px;
  }
  .word {
    height: 310px;
    .video {
      height: 220px !important;
      img {
        height: 181px !important;
      }
    }
  }
  .no {
    text-align: center;
    padding: 80px 0;
  }
}
</style>
